.bot {
  overflow: hidden;
}

.connect-spinner {
  display: flex;
  margin: auto;
  text-align: center;
}

.connect-spinner > .content {
  margin: auto;
}

.connect-spinner > .content > .icon {
  font-size: 64px;
}

.bot-init-loader-logo {
  max-width: 256px;
  max-height: 256px;
}

 .webchat__suggested-action{
  border-radius: 6px !important;
} 


.newStartConversation a {
  margin-left: 61px;
  text-decoration: none;
  background-color: #279989;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
}

.start-new-chat{
  background-color: #279989;
  border-radius: 5px;
  border: unset;
}


.conversationEnded
{
  margin-left: 61px;
}

.conversationEnded p{
  margin-bottom: 20px;
  margin-top: 5px;
}



.newStartConversation {
  margin-top: 10px;
  margin-bottom: 10px;
}

.filetxt div {
  align-items: center;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 7px;
  color: #565656;
  display: flex;
  justify-content: center;
  margin: 5px 10px;
  padding: 10px;
  word-break: break-word;
  text-align: center;
}

button.timeslot-btn {
  background: #fff;
  border: 1px solid #269989;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 5px;
  padding: 10px 5px;
}
.timeslot-list{
  list-style: none;
}
button.time-confirm-btn {
  background: #269989;
  border: initial;
  border-radius: 5px;
  color: #fff;
  padding: 11px 5px;
  position: absolute;
  top: 0;
  left: 0;
}
button.cal-submit-btn {
  background-color: #269989;
  border: initial;
  border-radius: 5px;
  color: #fff;
  height: 35px;
  margin-top: 10px;
  width: 216px;
}

.ca-loader {
  margin-top: -30px;
  width: 81px !important;
  padding: unset !important;
  margin-bottom: 0px !important;
}
 
 
.cal-submit-btn:disabled {
  height: 35px;
  border-radius: 4px;
  background: #269988;
  color: #fff;
  text-transform: uppercase;
  width: 216px;
  padding: 6px 0;
}
 
.cal-submit-btn:hover,
.cal-submit-btn:focus,
:not(.btn-check)+.btn:active {
  background: #3fccb7;
  color: #fff;
}
.newStartConversation-form a {
  background-color: #279989;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
  text-decoration: none;
  display: inline-block;
}
.newStartConversation-form {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* .conversationEnded-form
{
  margin-left: 61px;
} */
.conversationEnded-form p{
  margin-bottom: 20px;
  margin-top: 5px;
}
label.form-label.cal-label {
  font-size: 14px;
}
input.form-control.cal-input {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #645b5b;
  line-height: 24px;
  padding: 8px;
  width: 200px;
}
.main-input{
  margin-bottom: 10px;
}
.cal-content {
  padding: 10px;
}
.cal-form-name {
  margin-top: 10px;
  margin-bottom: 5px;
}
 
.calendar-view .react-calendar__month-view__weekdays__weekday abbr {
  text-transform: capitalize;
}

abbr[title] {
  text-decoration: unset;
}
 
.timeslot-view ul {
  padding-inline-start: 0px !important;
}
.calendar-view .react-calendar__tile--now {
  background: #2699897d !important;
  border-radius: 50%;
}
.calendar-view .react-calendar__tile--active {
  background: #269989 !important;
  color: white;
  border-radius: 50%;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: #e1f5f2;
  border-radius: 50%;
}

.calendar-view .react-calendar {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  border: unset;
  width: 240px;
  padding: 5px;
}

.calendar-view .react-calendar__navigation button {
  min-width: 30px;
  background: none;
}
.calendar-view .react-calendar__month-view__weekdays__weekday {
  padding: 0.3em;
}
.calendar-view .timeslot-view h2 {
  font-size: 14px;
  margin-top: 25px;
}

.calendar-view .timeslot-list {
  list-style: none;
  position: relative;
  width: 45%;
  float: left;
  margin: 2px 2px;
  cursor: pointer;
}

.calendar-view li.timeslot-list button {
  width: 100%;
  margin-bottom: 0;
  cursor: pointer;
}

.webchat__bubble__content:has(> .calendar-view), .webchat__bubble__content:has(> .cal-content) {
  background: unset !important;
  border: unset !important;
}
.calendar-view, .cal-content {
  padding: 0;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cal-bubble {
  background: #dff0ee;
  border: 1px solid #dff0ee;
  border-radius: 7px;
  color: rgba(12, 46, 41, .75);
  padding: 10px;
  margin-bottom: 10px;
}

.chat-endedw1 {
  display: inline-block;
}
.mx20 {
  margin: 20px 0;
}

.webchat__send-box:has(> .webchat__suggested-actions) .webchat__send-box__main {
  visibility: hidden !important;
  display: none !important;
}